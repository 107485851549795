<template>
    <div class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-4 mt-12 md:mt-16 w-full mx-auto">
        <Link v-for="category in categories" :key="category.id" class="mx-auto" :href="category.href">
            <div class="flex flex-col justify-between w-[175px] md:max-w-[200px] shadow-xl">
                <div class="hidden md:flex bg-white justify-center">
                    <img :src="category.img" :alt="category.name" class="w-[150px] h-[150px] object-contain">
                </div>
                <div class="bg-[#FFD5D0] shadow-2xl">
                    <button class="flex flex-row p-3 items-center gap-3 font-bold text-black">
                        <span class="bg-frontend-brand text-white p-2">
                            <ArrowRight class="w-3" />
                        </span>
                        {{ category.name }}
                    </button>
                </div>
            </div>
        </link>
    </div>
</template>

<script setup>
import accessoriesImg from '@frontendImages/tempapi/accessories.png'
import serviceKitsImg from '@frontendImages/tempapi/servicekits.png'
import oilImg from '@frontendImages/tempapi/oil.png'
import merchImg from '@frontendImages/tempapi/merch.png'
import ArrowRight from "@/Components/Frontend/Svg/ArrowRight.vue";
import { Link } from '@inertiajs/vue3'

//TODO: Fetch live data from API
const categories = [
    {id: 1, name: 'Service Kits', img: serviceKitsImg, href: '/products?parts_types[]=Service%20Kit'},
    {id: 2, name: 'Accessories', img: accessoriesImg, href: '/products?tags[]=Accessories'},
    {id: 3, name: 'Oil & Lubricants', img: oilImg, href: '/products?tags[]=Oils%20and%20Lubricants'},
    {id: 4, name: 'Merchandise', img: merchImg, href: '/products?tags[]=Merchandise'},
]

</script>

<template>
    <div class="mt-[250px] xs:mt-36 sm:mt-56 flex flex-col md:mt-28 w-full mx-auto px-2 sm:px-6">
        <div class="flex flex-row justify-between items-center">
            <p class="text-3xl text-center lg:text-left w-full text-black font-bold">
                Latest Stock
            </p>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
            <GlobalProduct v-for="product in products" :key="product.id" :product="product" :isIndex="isIndexToggle" />
        </div>
    </div>
</template>

<script setup lang="ts">
import GlobalProduct from "@/Components/Frontend/Global/GlobalProduct.vue";
import {onMounted, ref} from "vue";
import { type Product, getRandomProduct } from "@/util/product";
import {useFetch} from "@vueuse/core";

const isIndexToggle = ref(true);

//TODO: Fetch lists from API
const productResponse = ref<ProductResponse | null>(null)
const products = ref<Product[]>()
const productsLoading = ref(true)
//TODO: Recent products endpoint?

onMounted(async () => {
    await fetchProducts()
})
async function fetchProducts() {
    productsLoading.value = true
    const { data, error, } = await useFetch(route('api.latest-products')).json()
    if (error.value) {
        console.error(error.value)
    } else {
        productResponse.value = data.value as ProductResponse
        products.value = productResponse.value.data.slice(0, 6) as Product[]
    }
    productsLoading.value = false
}


</script>

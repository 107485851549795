<template>
    <Menu as="div" class="relative text-nowrap inline-block text-left">
        <div class="flex justify-center">
            <MenuButton class="inline-flex w-60 lg:w-72 justify-between gap-x-1.5 rounded-sm bg-frontend-bg px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-frontend-header hover:bg-frontend-header/90">
                <span class="block truncate text-sm">{{ selected ? selected.name : `Select ${name}` }}</span>
                <ChevronDown class="w-4 text-white" />
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 w-52 md:w-44 lg:w-60 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
                <div ref="container" class="overflow-y-auto overflow-x-hidden max-h-40">
                    <MenuItem v-for="option in options" @click="selected = option" as="div"
                              class="block px-4 py-2 text-sm text-black hover:text-white hover:bg-frontend-bg font-semibold">
                        {{option.name}}
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from '@headlessui/vue'
import {useScroll, useVModel} from "@vueuse/core";
import {ref, watch} from "vue";
import ChevronDown from "@/Components/Frontend/Svg/ChevronDown.vue";

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    options: {
        type: Array,
        required: true
    },
    selected: {
        type: Object,
        default: null
    },
    isDoneScrolling: {
        type: Boolean,
        default: false
    }
})
const emits = defineEmits(['update:selected', 'update:isDoneScrolling'])
const selected = useVModel(props, 'selected', emits)
const isDoneScrolling = useVModel(props, 'isDoneScrolling', emits)
const container = ref()
const { arrivedState } = useScroll(container)

watch(arrivedState, (value) => {
    if (value.bottom) isDoneScrolling.value = true
})
</script>

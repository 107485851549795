<template>
    <div class="flex flex-col items-center justify-center h-full mt-36 w-full px-8 mx-auto text-center text-white">
        <h1 class="text-4xl md:text-6xl font-bold ">{{ theme.currentManufacturer.headline }}</h1>
        <p class="mt-12 md:text-lg">
            {{ theme.currentManufacturer.hero }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { useThemeStore } from "@/stores/ThemeStore";

const theme = useThemeStore()
</script>

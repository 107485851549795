<template>
    <div class="w-full bg-frontend-brand h-[484px] sm:h-[181px] mt-20 md:mt-28">
        <div class="max-w-[1000px] mx-auto flex flex-col sm:flex-row items-center justify-around h-full">
            <div v-for="feature in features" class="flex flex-col items-center max-w-[130px]">
                <component :is="feature.icon" class="w-10 h-10 text-white" />
                <p class="text-white text-center mt-4">
                    {{ feature.description }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import Car from "@/Components/Frontend/Svg/Car.vue";
import GBP from "@/Components/Frontend/Svg/GBP.vue";
import Medal from "@/Components/Frontend/Svg/Medal.vue";
import Shop from "@/Components/Frontend/Svg/Shop.vue";

const features = [
    { id: 1, description: 'Fully Road Legal As Standard', icon: Car },
    { id: 2, description: 'UK’s Largest ATVs & UTVs Importer', icon: GBP },
    { id: 3, description: 'Premium Features Included as Standard', icon: Medal },
    { id: 4, description: 'Direct Parts Backup, Stocked in the UK', icon: Shop },
]
</script>
